<template>
  <div class="warp">
    <!-- 头部 -->
    <div class="warpTop">
      <div class="warpTopsCent">
        <!-- 左侧视频 -->
        <img class="warpTopsCentL" :src="detail.img" />

        <!-- 右侧详情 -->
        <div class="warpTopsCentR">
          <!-- 标题 -->
          <div class="title dis_flex">
            <div class="courType">音频</div>
            {{ detail.name }}
          </div>
          <div class="detail-ter m-t-20 flex-c">
            <div class="ter-list">
              <div class="terbox"> <el-image class="ter-img" :src="detail ? detail.tImg : ''" fit="cover"></el-image>
              </div>
              <span class="ter">讲师：{{ detail ? detail.terName : "" }}</span>
            </div>
            <el-divider direction="vertical"></el-divider>
            <el-rate :value="average" disabled :colors="['#99A9BF', '#F7BA2A', '#FF9900']"> </el-rate>
          </div>
          <!-- 价格 -->
          <div class="picr">
            <div>价格</div>
            <div class="spanb">￥{{ detail.entrantsPrice ? detail.entrantsPrice : 0 }}</div>
            <!-- <div class="oldprice">￥{{ detail.olderPrice ? detail.olderPrice : 0 }}</div> -->
          </div>
          <!-- 报名资讯按钮 -->
          <div class="btn">
            <div class="keep-learn keep-check" v-if="!IsPurchase" @click="orderClick()">立即购买</div>
            <div class="keep-learn" v-else>已购买</div>
          </div>
        </div>
      </div>
    </div>
    <div class="content">
      <div class="box-content">
        <!-- 选项栏 -->
        <div class="warpnav">
          <div class="warpnavs">
            <div class="warpnavsCons" :class="navIndex == 2 ? 'on' : ''" @click="navs(2)">
              <span>课程列表</span>
              <div v-if="navIndex == 2"></div>
            </div>
            <div v-if="productLine == 2" class="warpnavsCons" :class="navIndex == 3 ? 'on' : ''" @click="navs(3)">
              <span>课程资料</span>
              <div v-if="navIndex == 3"></div>
            </div>
            <div v-if="(productLine = 2)" class="warpnavsCons" :class="navIndex == 4 ? 'on' : ''" @click="navs(4)">
              <span>课程测试</span>
              <div v-if="navIndex == 4"></div>
            </div>
            <div class="warpnavsCons" :class="navIndex == 5 ? 'on' : ''" @click="navs(5)">
              <span>课程评价</span>
              <div v-if="navIndex == 5"></div>
            </div>
          </div>
        </div>
        <!-- 课程详情内容 -->
        <div class="warpCon" v-if="navIndex == 1">
          <div class="apple">
            <div class="appleLeft">
              <div class="appleLefts" v-html="ImgSizeLimit(detail.content)"></div>
            </div>
            <!-- 右侧推荐 -->
            <you-love :id="id" :type="7">相关课程</you-love>
          </div>
        </div>
        <!-- 直播章节 -->
        <div class="warpCon" v-if="navIndex == 2">
          <audio-chat v-if="detail.audioChapters" :id="id" :classId="classId" :IsPurchase="IsPurchase"
            :list="detail.audioChapters" :isFreeze="isFreeze"></audio-chat>
        </div>
        <!-- 课程资料 -->
        <div class="warpCon" v-if="navIndex == 3">
          <course-data v-if="navIndex == 3" :type="3" :id="id" :isPurchase="IsPurchase" />
        </div>
        <!-- 课程模考 -->
        <div class="warpCon" v-if="navIndex == 4">
          <course-task v-if="navIndex == 4" :type="3" :id="id" :isPurchase="IsPurchase" />
        </div>
        <!-- 课程评价 -->
        <div class="warpCon" v-if="navIndex == 5">
          <score-evaluation v-if="navIndex == 5" :type="7" :typeId="id" :typeNmae="detail.title"
            :IsPurchase="IsPurchase" />
        </div>
      </div>
      <you-love :id="id" class="youlove" :ifteacher="true" :type="7" />
    </div>

    <div class="mask_layer" v-if="isFreeze == 1">
      课程已冻结，请联系老师
    </div>
  </div>
</template>
<script>
import Bus from "@/api/bus";
import Vue from "vue";
import { getLbClassCourseById } from "@/api/home";
import { OrderClient } from "@/api/orderClient";
let orderClient = new OrderClient();
import { getToken } from "@/api/cookies";
import { Evaluation } from "@/api/scoreEvaluation";
let evaluation = new Evaluation();
import { Know } from "@/api/know";
let know = new Know();
import { checkStudyCode } from "@/api/home.js";

import CourseData from "@/components/Know/course/courseData.vue";
import courseTask from "@/components/Know/course/courseTask.vue";
import scoreEvaluation from "@/components/Know/course/scoreEvaluation.vue";
import audioChat from "@/components/Know/course/audioChat.vue";
import YouLove from "@/components/Know/youLove.vue";
export default {
  components: { audioChat, YouLove, CourseData, courseTask, scoreEvaluation },

  data() {
    return {
      detail: {},
      navIndex: 1,
      IsPurchase: false, // 章节列表
      id: null,
      classId: null,
      href: "",
      average: 0, //评分
      productLine: null,
      cardCode: null, // 学习卡id
      isFreeze: null, // 是否冻结 0 否 1 是
    };
  },

  created() {
    this.cardCode = eval(this.$route.query.cardCode);
    this.checkStudyCode();
    this.href = window.location.href;
    this.id = this.$route.query.id;
    this.classId =
      this.$route.query.classId &&
        this.$route.query.classId != "undefined" &&
        this.$route.query.classId != "null" &&
        Number(this.$route.query.classId) != NaN
        ? this.$route.query.classId
        : null;
    this.getDetail();
    /* 评价列表 */
    evaluation.getPresentScore(7, this.id).then((res) => {
      this.average = Math.round(res.average);
    });
    this.navIndex = 2;
    know.updateCourseAllClicks(4, this.id);
  },
  methods: {
    /* 验证学习卡 */
    checkStudyCode() {
      if (this.cardCode) {
        let data = {
          cardCode: this.cardCode,
        };
        checkStudyCode(data).then((res) => {
          if (res.code == 0) {
            this.IsPurchase = true;
            this.$store.commit("IS_PURCHASE_CHANGE", true);
          }
        });
      }
    },
    // 直播课程详情信息
    getDetail() {
      know.getPcAudioTeachDetail(this.id, this.classId).then((res) => {
        if (!this.cardCode) {
          this.IsPurchase = res.msg.IsPurchase;
        }
        this.productLine = res.msg.audioTeach.productLine;
        this.detail = res.msg.audioTeach;
        this.isFreeze = res.data.isFreeze;
        this.$forceUpdate();
      });
    },
    // 预约按钮
    orderClick() {
      let token = getToken();
      if (token) {
        orderClient
          .addOrders(
            this.detail.id,
            localStorage.getItem("userId"),
            this.detail.title,
            7,
            this.detail.title,
            this.detail.kind
          )
          .then((res) => {
            if (res.code == 0) {
              this.$router.push({
                name: "个人中心订单页",
                params: { orderNumber: res.msg },
              });
            } else {
              this.$message.error(res.msg);
            }
          });
      } else {
        Vue.prototype.goLoginView(true);
      }
    },
    // 标签页切换
    navs(val) {
      this.navIndex = val;
    },
  },
};
</script>
<style lang="less" scoped>
.warp {
  width: 100%;
  height: 100%;
  overflow: hidden;
  margin-bottom: 60px;

  // 头部
  .warpTop {
    width: 100%;
    background: #ffffff;
    box-shadow: 0px 4px 8px 1px rgba(204, 204, 204, 0.20000000298023224) inset;
    padding: 40px calc(50% - 610px);

    .warpTopsCent {
      width: 100%;
      height: 225px;
      display: flex;
      justify-content: flex-start;
      align-items: center;

      // 左侧视频
      .warpTopsCentL {
        width: 338px;
        height: 225px;
        border-radius: 8px 8px 8px 8px;
        opacity: 1;
        margin-right: 28px;
        flex-shrink: 0;
      }

      // 右侧详情
      .warpTopsCentR {
        width: 100%;
        height: 100%;
        position: relative;

        // 标题
        .title {
          font-size: 24px;
          font-family: Microsoft YaHei-Regular;
          font-weight: 400;
          color: #333333;
          line-height: 24px;

          .courType {
            height: 24px;
            background: #ffffff;
            border-radius: 4px 4px 4px 4px;
            opacity: 1;
            border: 1px solid #ff7b1c;
            font-size: 14px;
            font-family: Microsoft YaHei-Regular;
            font-weight: 400;
            color: #ff7b1c;
            line-height: 22px;
            padding: 0px 11px;
            margin-right: 8px;
            flex-shrink: 0;
          }
        }

        // 价格
        .picr {
          width: 360px;
          height: 54px;
          line-height: 54px;
          background: #f5f7f9;
          border-radius: 4px 4px 4px 4px;
          opacity: 1;
          display: flex;
          align-items: center;
          padding: 0px 20px;
          font-size: 16px;
          font-family: Microsoft YaHei-Regular;
          font-weight: 400;
          color: #999999;
          line-height: 16px;
          margin-top: 20px;

          .spanb {
            font-size: 24px;
            color: #ff5e51;
            margin: 0px 8px;
          }
        }

        // 报名资讯按钮
        .btn {
          width: auto;
          height: 46px;
          margin-top: 30px;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          position: absolute;
          bottom: 0px;

          // 立即报名
          .keep-learn {
            height: 40px;
            padding: 0px 28px;
            line-height: 38px;
            font-size: 16px;
          }
        }
      }
    }
  }

  // 选项栏
  .warpnav {
    width: 100%;
    margin: 0px auto;
    height: 66px;
    background: #ffffff;
    border-bottom: 1px solid #dddddd;
    padding: 0px 32px;
    line-height: 66px;

    // 缩宽
    .warpnavs {
      height: 100%;
      margin: auto;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      // 知识套餐概述
      /*  .warpnavsCons {
        width: auto;
        height: auto;
        font-size: 16px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #666666;
      } */
      // 竖线

      // 转换内容 选中
      .on {
        height: 100%;

        span {
          font-weight: bold !important;
          color: #333333 !important;
        }

        div {
          width: 30px;
          height: 3px;
          background: linear-gradient(90deg, #ff4027 0%, #ff5400 100%);
          margin-left: calc(50% - 15px);
          margin-top: -3px;
        }
      }

      // 转换内容 未选中
      .warpnavsCons {
        height: 100%;
        margin-right: 60px;

        span {
          font-size: 16px;
          font-family: Microsoft YaHei-Regular;
          font-weight: 400;
          color: #666666;
          cursor: pointer;
        }
      }
    }
  }

  // 直播课内容
  .warpCon {
    width: 100%;
    margin: 0px auto;
    background: #fff;

    // 缩宽
    .apple {
      width: 1200px;
      height: 100%;
      margin: 30px auto;
      display: flex;
      justify-content: space-between;
      align-items: flex-start;

      .appleLeft {
        width: 750px;
        height: auto;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        background: #ffffff;
        display: flex;
        justify-content: center;
        align-items: flex-start;

        .appleLefts/deep/img {
          width: 700px;
          height: auto;
          margin-top: 25px;
        }
      }

      .appleRight {
        width: 430px;
        height: 1292px;
        background: #ffffff;

        .appleRightTop {
          width: auto;
          height: 20px;
          margin: 16px 22px;
          border-left: 6px solid #3855f9;
          padding: 0px 12px;
          font-size: 16px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #7e7e7e;
        }

        img {
          width: 376px;
          height: 209px;
          margin-top: 25px;
          margin-left: 27px;
          background: rgba(0, 0, 0, 0);
        }
      }
    }
  }
}

.content {
  display: flex;
  width: 1220px;
  margin: 20px calc(50% - 610px);

  .box-content {
    width: 880px;
    margin-right: 20px;
  }

  .youlove {
    width: 320px;
    background: #ffffff;
    border-radius: 8px 8px 8px 8px;
    flex-shrink: 0px;
  }
}

.el-popover {
  overflow: hidden;
  padding: 0px;
}

.mask_layer {
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 999999;
  position: fixed;
  background-color: #000000;
  opacity: 0.6;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
}</style>
